import { Card, Col, Row } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { orderDepartmentInfo } from 'constant';

import { ArticleOrderSummary } from './ArticleOrderSummary';

export const ArticleOrderAccepted = ({ offer }) => {
  return (
    <>
      <ArticleOrderSummary offer={offer} />
      <Card>
        <Row justify={'center'}>
          <Col span={8}>
            <div className="gx-d-flex gx-align-items-center gx-py-2 gx-px-4">
              <MailOutlined className="gx-fs-xxxl gx-mr-3" />

              <div className="gx-d-flex gx-flex-column">
                <span className="gx-font-weight-medium">
                  <FormattedMessage id="article.order.departmentSupervisor" />
                </span>
                <span>{orderDepartmentInfo.supervisor}</span>
                <FormattedHTMLMessage
                  id="appModule.phoneWithInternal"
                  values={{ number: orderDepartmentInfo.phone, internal: orderDepartmentInfo.internal }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};
