import { Button, Col, Row } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import RefundToAuthor from 'components/ArticleOrder/status/RefundToAuthor.jsx';
import { useOrderUnitDecisionMutation } from 'services';
import { openNotification } from 'utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';

const SendToCommitteeActions = ({ offerId, step }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sendToCommittee] = useOrderUnitDecisionMutation();

  const handleSendToCommittee = async () => {
    const { error } = await sendToCommittee({
      id: offerId,
      body: {
        status: 2,
      },
    });

    if (error) return dispatch(fetchError(formatMessage({ id: 'article.form.fetchError' }, { error: error.status })));

    openNotification({
      message: formatMessage({ id: 'appModule.actionSuccessfully' }),
    });

    navigate('/madde-teklifleri/tum-maddeler');
  };

  return (
    <Row gutter={24}>
      <Col
        xs={24}
        md={12}
      >
        <RefundToAuthor
          offerId={offerId}
          step={step}
        />
      </Col>
      <Col
        xs={24}
        md={12}
      >
        <Button
          block
          className="modalBtnStyle"
          onClick={handleSendToCommittee}
        >
          <FormattedMessage id="send.to.committee" />
        </Button>
      </Col>
    </Row>
  );
};
export default SendToCommitteeActions;
